<template>
    <nav class="navbar navbar-default">
        <div class="container-fluid">
            <div class="pull-left">
                <p class="navbar-text hidden-xs hidden-sm" style="color: black">
                    RC-RESEARCH Manager
                </p>
                <i class="las la-bars visible-xs visible-sm" data-toggle="collapse" data-target="#left-menu" aria-expanded="false"></i>
            </div>
            <div class="pull-right">
                <p class="navbar-text hidden-xs hidden-sm">
                    Ciao, {{adminEmailName}}
                </p>
            </div>
        </div>
    </nav>
</template>

<script>
    import {StorageHelper} from "../../common/storageHelper";

    export default {
        name: "Header",
        data : function () {
            return {
                adminEmailFirstName: "",
                adminEmailName: "",
                adminEmailEmail: "",
            }
        },
        mounted() {
            const user = StorageHelper.getUser();
            this.adminEmailFirstName = user.first_name;
            this.adminEmailName = user.first_name + " " + user.last_name;
            this.adminEmailEmail = user.email;
        }
    };
</script>
