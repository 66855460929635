<template>
	<div id="side-menu">
		<div class="visible-xs visible-sm sidebar-close">
			<button type="button" class="close" data-toggle="collapse" data-target="#left-menu"><i class="las la-times"></i></button>
		</div>
		<div  id="section-reservation">
			<h4 class="margin-bottom-10">Amministrazione</h4>
			<ul class="nav nav-pills nav-stacked">
				<li role="presentation">
					<router-link to="/home">
						<i class="las la-list"></i>
						Procedure
					</router-link>
				</li>

			</ul>
		</div>
        <div class="margin-top-40" id="section-administration" v-if="projectResearches.length">
            <h4 class="margin-bottom-10">Procedure</h4>
            <ul class="nav nav-pills nav-stacked">


                <li v-for="project in projectResearches" v-bind:key="project.id" role="presentation">
                    <router-link :to="{ name: 'research_project_detail', params: { research_project_id: project.id }}">
                        <i class="las la-dna"></i>
                        {{project.name}}
                    </router-link>
                </li>
            </ul>
        </div>
		<ul class="nav nav-pills nav-stacked pushed-down" v-if="user">
			<li id="account-link" role="presentation" class="margin-bottom-20" >
				<a href="" @click.stop.prevent="">
					<i class="las la-user-circle"></i>
					{{user.email}}
				</a>
			</li>
			<li role="presentation">
				<a href="" @click.prevent.stop="logout" id="logout_button">
					<i class="las la-door-open"></i>
					Logout
				</a>
			</li>
			<li role="presentation" class="disabled" style="padding-left:30px;">
				<a href="#">
					v 0.1
				</a>
			</li>
		</ul>
	</div>
</template>

<script>
	import {StorageHelper} from "../../common/storageHelper";
    import {getResearchProjects} from "../../api";

	export default {
		name: "SideMenu",
        data: function() {
            return {
                projectResearches: [],
            };
        },
        mounted() {
            this.getResearchProjects()
        },
		computed: {
			user: function () {
				return StorageHelper.getUser()
			}
		},
		methods: {
			logout() {
				StorageHelper.setToken(null);
				StorageHelper.setUser(null);
				this.$router.push({ name: "login" });
			},
            async getResearchProjects(page = 1) {
                this.loading = true;
                try {
                    let projectResearchResponse = await getResearchProjects(page);
                    this.projectResearches = projectResearchResponse.results;

                    this.loading = false;
                } catch (e) {
                    this.loading = false;
                    console.log(e)
                }
            },
		}
	};
</script>
